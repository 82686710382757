import SubscribeForm from "./SubscribeForm";

export const BlogPosts = () => {
  return (
    <section
      className="common-padding bg-gradient-to-t from-black via-black to-zinc"
      id="blog"
    >
      <div className="screen-max-width">
        <div className="flex flex-col items-center">
          <h2 className="hiw-title">Blog Posts Coming Soon</h2>
          <p className="hiw-subtitle">
            Stay tuned! New insights and updates will be available shortly.
          </p>
        </div>
        <div className="mt-10 md:mt-15 mb-14">
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
};
