import { useEffect, useState } from "react";

import { TypeAnimation } from "react-type-animation";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const Hero = () => {
  const windowDimensions = useWindowDimensions();
  const [fontSizeDisplay, setfontSizeDisplay] = useState("8em");
  useEffect(() => {
    if (windowDimensions?.width) {
      setfontSizeDisplay(windowDimensions.width < 1200 ? "2em" : "8em");
    }
  }, [windowDimensions]);

  return (
    <section
      className="w-full h-screen bg-cover bg-center z-10 relative"
      style={{ backgroundImage: "url(/assets/images/landing_home.webp)" }}
    >
      <TypeAnimation
        sequence={[
          "Landing The Future of Software Development.",
          3000,
          "We define the future.",
          3000,
        ]}
        speed={40}
        cursor={false}
        key={fontSizeDisplay}
        style={{
          fontSize: fontSizeDisplay,
          fontFamily: "SF Pro Display",
          fontWeight: 400,
          padding: "10rem",
        }}
        repeat={Infinity}
        className="flex justify-center items-center text-center w-full h-full px-5"
      />

      <div className="w-screen overflow-hidden mt-10 bg-gradient-to-b from-black from-60% via-black via-20% to-[#E31E44] flex align-center items-center pb-20">
        <p className="flex justify-center items-center text-center w-full text-gray-100 font-light text-lg h-full px-5">
          Copyright @ {new Date().getFullYear()} AT Soft - Commissioned by AT
          Store Sarajevo. All rights reserved.
        </p>
      </div>
    </section>
  );
};
