import {
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
} from "../utils";

export const navLists = [
  { title: "Home", link: "/" },
  { title: "Solutions", link: "/#solutions" },
  { title: "Team", link: "/#team" },
  { title: "Careers", link: "/#contact" },
  { title: "Blog", link: "/#blog" },
  { title: "Contact Us", link: "/#contact" },
];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Custom Software Development",
      "AT Soft provides tailor-made software solutions to meet your unique business needs. \n From web applications to enterprise software, we create scalable and efficient systems that drive growth.",
    ],
    video: highlightFirstVideo,
    videoDuration: 8,
  },
  {
    id: 2,
    textLists: [
      "Cloud & AI Solutions",
      "Unlock the power of cloud computing and artificial intelligence with AT Soft.",
      "We develop intelligent, data-driven solutions that enhance automation, optimize operations, and boost productivity.",
    ],
    video: highlightSecondVideo,
    videoDuration: 8,
  },
  {
    id: 3,
    textLists: [
      "Mobile App Development",
      "Elevate your digital presence with innovative mobile applications.",
      "AT Soft specializes in iOS and Android development, delivering seamless, user-friendly apps that engage and retain customers.",
    ],
    video: highlightThirdVideo,
    videoDuration: 8,
  },
  {
    id: 4,
    textLists: [
      "Cybersecurity & IT Consulting",
      "Protect your business with AT Soft’s advanced cybersecurity solutions.",
      "We provide IT consulting, risk assessment, and security enhancements to keep your data and infrastructure safe.",
    ],
    video: highlightFourthVideo,
    videoDuration: 8,
  },
];

export const footerLinks = [
  { title: "Privacy Policy", link: "privacy-policy" },
  { title: "Terms of Service", link: "terms-of-service" },
];
