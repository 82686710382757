import { FaInstagram, FaLinkedinIn } from "react-icons/fa";

import { logo } from "../utils";

export const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-black from-10% via-[#171717] to-[#171717] to-80% text-gray-400 py-12 px-6 md:px-20">
      {/* Logo */}
      <div className="flex justify-center py-5 mb-8 md:mb-0">
        <a href="/">
          <img src={logo} alt="Logo" width={150} height={100} />
        </a>
      </div>
      <div className="max-w-7xl flex w-full justify-center h-full mx-auto">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row justify-between">
          {/* Links */}
          <div className="grid grid-cols-1 gap-10 lg:gap-0 md:gap-10 md:grid-cols-2 lg:grid-cols-3">
            <div>
              <h3 className="text-white font-semibold mb-3">Solutions</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#solutions" className="hover:text-white">
                    Solutions
                  </a>
                </li>
                <li>
                  <a href="#solutions" className="hover:text-white">
                    Custom Software Development
                  </a>
                </li>
                <li>
                  <a href="#solutions" className="hover:text-white">
                    Cloud & AI Solutions
                  </a>
                </li>
                <li>
                  <a href="#solutions" className="hover:text-white">
                    Mobile App Development
                  </a>
                </li>
                <li>
                  <a href="#solutions" className="hover:text-white">
                    Cybersecurity & IT Consulting
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-white font-semibold mb-3">About Us</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#team" className="hover:text-white">
                    Team
                  </a>
                </li>
                <li>
                  <a href="#partners" className="hover:text-white">
                    Partners
                  </a>
                </li>
                <li>
                  <a href="#contact" className="hover:text-white">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#contact" className="hover:text-white">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://atstore.ba" className="hover:text-white">
                    AT Store
                  </a>
                </li>
              </ul>
            </div>
            <div className="text-sm flex flex-col gap-2 items-center justify-between w-full">
              <p className="text-gray-400 leading-6 text-left">
                AT Soft is a software development company committed by AT Store
                and is part of the group AT Networks. We specialize in
                delivering innovative software solutions, tailored to meet the
                needs of businesses worldwide.{" "}
              </p>
              <span>
                {" "}
                For inquiries, partnerships, or support, contact us at{" "}
                <a
                  href="mailto:info@atsofts.com"
                  className="text-white underline"
                >
                  info@atsofts.com
                </a>
                .
              </span>

              {/* Social Icons */}
              <div className="flex space-x-4 mt-6 md:mt-5 flew-row justify-start w-full">
                {/* <a href="#" className="text-gray-400 hover:text-white">
                  <FaFacebookF size={20} />
                </a> */}
                <a
                  href="https://www.instagram.com/at.soft"
                  className="text-gray-400 hover:text-white"
                >
                  <FaInstagram size={20} />
                </a>
                <a
                  href="https://www.linkedin.com/company/at-soft/"
                  className="text-gray-400 hover:text-white"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="text-center text-xs mt-6 border-t border-gray-700 pt-4 w-full flex-1 flex-col">
        Copyright © 2025 AT Soft - Commissioned by AT Store Sarajevo. All rights
        reserved.
        <div className="mt-2 gap-3 flex flex-row justify-center">
          <a href="/privacy-policy" className="hover:text-white">
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="/terms-of-service" className="hover:text-white">
            Terms of Services
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
