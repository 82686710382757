import { useState } from "react";

const MAILCHIMP_URL =
  "https://atsofts.us11.list-manage.com/subscribe/post?u=228d0a77f71c614ab42c2225e&amp;id=3c1965e581&amp;f_id=006398e1f0";

const SubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("idle");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !email.includes("@")) {
      setMessage("Please enter a valid email.");
      setStatus("error");
      return;
    }

    const formData = new FormData();
    formData.append("EMAIL", email);

    fetch(MAILCHIMP_URL, {
      method: "POST",
      body: formData,
      mode: "no-cors", // Avoid CORS issues
    })
      .then(() => {
        setMessage("You're subscribed! Check your inbox.");
        setStatus("success");
        setEmail("");
      })
      .catch(() => {
        setMessage("Subscription failed. Please Try again.");
        setStatus("error");
      });
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-4 w-full max-w-lg bg-black/50 backdrop-blur-md p-6 rounded-2xl shadow-lg">
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <input
            type="email"
            className="w-full px-5 py-3 rounded-xl border border-gray-600 bg-gray-900/50 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400 transition-all"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="btn" disabled={status === "loading"}>
            {status === "loading" ? "Subscribing..." : "Subscribe"}
          </button>
        </form>

        {message && (
          <p
            className={`text-center text-sm mt-2 ${
              status === "success" ? "text-green-400" : "text-red-400"
            }`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default SubscribeForm;
