import ProfileCard from "./ProfileCard";
import React from "react";
import { teamMembers } from "../constants/team-members";

const members = teamMembers;

const ProfileCarousel: React.FC = () => {
  return (
    <div className="carousel-card">
      {members.concat(members).map((member, i) => (
        <ProfileCard key={i} {...member} />
      ))}
    </div>
  );
};

export default ProfileCarousel;
