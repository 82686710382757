// import { ImgComparisonSlider } from "@img-comparison-slider/react";
import React from "react";

interface ProfileProps {
  quote: string;
  leftImage: string;
  rightImage: string;
  name: string;
  position: string;
}

const ProfileCard: React.FC<ProfileProps> = ({
  quote,
  leftImage,
  // rightImage,
  name,
  position,
}) => {
  return (
    <div className="flex flex-col gap-2 rounded-3xl">
      <div className="w-[256px] md:w-[512px] h-[246px] md:h-[512px] object-fit">
        {/* TODO: Implement when images are ready */}
        {/* <ImgComparisonSlider hover={true} className="rounded-3xl">
          <img
            slot="first"
            className="w-full h-full object-cover rounded-3xl"
            alt={name}
            src={leftImage}
          />
          <img
            slot="second"
            alt={name}
            className="w-full mih-h-fit object-cover rounded-3xl"
            src={rightImage}
          />
        </ImgComparisonSlider> */}
        <img
          slot="first"
          className="w-full h-full object-cover rounded-3xl"
          alt={name}
          src={leftImage}
        />
      </div>
      <div className="w-full h-full gap-3 text-white mt-3">
        <p className="text-[#E31E44] text-sm uppercase font-semibold">
          {position}
        </p>
        <p className="font-bold text-xl text-white">{name}</p>
        <p className="text-gray-400 pt-2 font-semibold text-xs md:text-lg">
          "{quote}"
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
