import admirLeft from "/assets/images/admirLeft.webp";
import admirRight from "/assets/images/admirRight.webp";
import aluminij from "/assets/images/partners/aluminij.png";
import amicus from "/assets/images/partners/amicus.png";
import asabanka from "/assets/images/partners/asabanka.png";
import atsoft from "/assets/images/atsoft.png";
import bayer from "/assets/images/partners/bayer.png";
import burch from "/assets/images/partners/burch.png";
import cocacola from "/assets/images/partners/cocacola.png";
import dm from "/assets/images/partners/dm.png";
import emirMLeft from "/assets/images/emirMLeft.png";
import emirMRight from "/assets/images/emirMRight.webp";
import emirTLeft from "/assets/images/emirTLeft.png";
import emirTRight from "/assets/images/emirTRight.webp";
import eurofarm from "/assets/images/partners/eurofarm.png";
import harisLeft from "/assets/images/harisLeft.webp";
import harisRight from "/assets/images/harisRight.webp";
import highlightFirstmv from "/assets/videos/highlight-first.mp4";
import highlightFourthmv from "/assets/videos/highlight-fourth.mp4";
import highlightSectmv from "/assets/videos/highlight-third.mp4";
import highlightThirdmv from "/assets/videos/highlight-sec.mp4";
import iomun from "/assets/images/partners/iomun.png";
import kaufland from "/assets/images/partners/kaufland.png";
import lidl from "/assets/images/partners/lidl.png";
import mahle from "/assets/images/partners/mahle.png";
import novartis from "/assets/images/partners/novartis.png";
import novonordisk from "/assets/images/partners/novonordisk.png";
import pause from "/assets/images/pause.svg";
import pfizer from "/assets/images/partners/pfizer.png";
import play from "/assets/images/play.svg";
import porsche from "/assets/images/partners/porsche.png";
import raiffeisen from "/assets/images/partners/raiffeisen.png";
import replay from "/assets/images/replay.svg";
import right from "/assets/images/right.svg";
import roche from "/assets/images/partners/roche.png";
import ssst from "/assets/images/partners/ssst.png";
import telemach from "/assets/images/partners/telemach.png";
import unicredit from "/assets/images/partners/unicredit.png";
import viatris from "/assets/images/partners/viatris.png";

// Partners part

export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;

export const logo = atsoft;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

// Team photos

export const harisImgLeft = harisLeft;
export const admirImgLeft = admirLeft;
export const emirMImgLeft = emirMLeft;
export const emirTImgLeft = emirTLeft;

export const harisImgRight = harisRight;
export const admirImgRight = admirRight;
export const emirMImgRight = emirMRight;
export const emirTImgRight = emirTRight;

// Partner logos

export const aluminijImg = aluminij;
export const amicusImg = amicus;
export const asabankaImg = asabanka;
export const bayerImg = bayer;
export const burchImg = burch;
export const cocacolaImg = cocacola;
export const dmImg = dm;
export const eurofarmImg = eurofarm;
export const iomunImg = iomun;
export const kauflandImg = kaufland;
export const lidlImg = lidl;
export const mahleImg = mahle;
export const novartisImg = novartis;
export const novonordiskImg = novonordisk;
export const pfizerImg = pfizer;
export const porscheImg = porsche;
export const raiffeisenImg = raiffeisen;
export const rocheImg = roche;
export const ssstImg = ssst;
export const telemachImg = telemach;
export const unicreditImg = unicredit;
export const viatrisImg = viatris;
