import { useEffect, useRef, useState } from "react";

import ProfileCarousel from "./ProfileCarousel";
import { animateWithGsap } from "../utils/animations";
import { useGSAP } from "@gsap/react";

export const Team = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLElement | null>(null);

  useGSAP(() => {
    animateWithGsap("#features_title", { y: 0, opacity: 1 });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.6 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="common-padding relative bg-gradient-to-t from-zinc via-zinc to-zinc overflow-hidden"
      id="team"
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full">
          <h1 className="section-heading" id="features_title">
            Team
          </h1>
        </div>
      </div>
      <div className="carousel-container">
        <div className={`carousel-track ${isVisible ? "animate-slide" : ""}`}>
          <ProfileCarousel />
        </div>
      </div>
    </section>
  );
};
