//@ts-nocheck
import { InputProps } from "../models/input-props.model";
import emailjs from "@emailjs/browser";
import { useState } from "react";
const InputField: React.FC<InputProps> = ({
  label,
  type = "text",
  name,
  required = false,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  useGSAP(() => {
    animateWithGsap("#formtitle", { y: 0, opacity: 1 });
  }, []);
  const validate = () => {
    if (required && !value.trim()) {
      setError(`Enter your ${name.toLowerCase()}.`);
    } else {
      setError("");
    }
  };
  return (
    <div className="mb-4">
      <label
        className={`block font-medium mb-1 ${
          error ? "text-red-600" : "text-gray-700"
        }`}
        htmlFor={name}
      >
        {label} {required && "*"}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={validate}
        className={`w-full px-4 py-3 rounded-md border ${
          error
            ? "border-red-600 bg-red-50 focus:ring-red-500"
            : "border-gray-300 focus:ring-gray-500"
        } focus:ring-2 outline-none`}
      />
      {error && (
        <p className="text-red-600 text-sm mt-1 flex items-center">
          <span className="mr-1">:warning:</span> {error}
        </p>
      )}
    </div>
  );
};
export default function Form() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState("");

  const sendEmail = (e: any) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        import.meta.env.VITE_REACT_APP_SERVICE_ID,
        import.meta.env.VITE_REACT_APP_TEMPLATE_ID,
        e.target,
        import.meta.env.VITE_REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage("");
          }, 5000);
        },
        () => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage("");
          }, 5000);
        }
      );
    e.target.reset();
  };

  return (
    <section
      className="h-full common-padding relative overflow-hidden"
      id="contact"
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full">
          <h1 className="section-heading" id="title">
            Contact Us
          </h1>
        </div>
        <div className="bg-neutral-900 flex items-center justify-center px-6 border-[red]-500 border rounded-xl">
          <div className="bg-neutral-900 text-white p-10 rounded-lg max-w-5xl w-full grid md:grid-cols-2 gap-8">
            {/* Left Section */}
            <div>
              <h2 className="text-4xl font-bold mb-4">
                Make this your first step.
              </h2>
              <p className="mb-4">
                After the form submission, <b>AT Soft</b>'s Team will contact
                you shortly.
              </p>
              <p>
                Looking to join us? <br />
                Visit our{" "}
                <a href="/careers" className="underline font-semibold">
                  Careers
                </a>{" "}
                page
              </p>
            </div>
            {/* Right Section - Form */}
            <form
              className="grid grid-cols-1 sm:grid-cols-2 gap-4"
              onSubmit={sendEmail}
            >
              <div className="col-span-1">
                <label className="block font-medium mb-1">First name*</label>
                <input
                  type="text"
                  name="from_name"
                  aria-label="Name"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                  required
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium mb-1">Last name*</label>
                <input
                  type="text"
                  aria-label="Last Name"
                  name="from_lastname"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                  required
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium mb-1">Job title</label>
                <input
                  type="text"
                  name="jobtitle"
                  aria-label="Job Title"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium mb-1">Company name</label>
                <input
                  type="text"
                  name="companyname"
                  aria-label="Company Name"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium mb-1">Email*</label>
                <input
                  type="email"
                  aria-label="Email"
                  name="email_from"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                  required
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium mb-1">
                  How can we help?*
                </label>
                <select
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500"
                  name="topic"
                  aria-label="Topic"
                  required
                >
                  <option value="">Please choose an option</option>
                  <option value="consulting">Consulting</option>
                  <option value="development">Development</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="col-span-1 sm:col-span-2">
                <label className="block font-medium mb-1">
                  Let us know more about what you’re looking for*
                </label>
                <textarea
                  name="message"
                  aria-label="Message"
                  className="w-full p-3 border border-gray-300 text-black rounded-md focus:ring-2 focus:ring-red-500 h-24"
                  required
                ></textarea>
              </div>
              <div className="flex flex-col gap-2 sm:col-span-2 flex justify-center sm:justify-start">
                <button className="btn" disabled={isSubmitting}>
                  Send Message
                </button>
                {stateMessage && (
                  <p
                    className={`mt-4 text-md w-full font-semibold text-center ${
                      stateMessage.includes("wrong")
                        ? "text-red-600"
                        : "text-green-600"
                    }`}
                  >
                    {stateMessage}
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
