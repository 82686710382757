import { AnimatePresence, motion } from "framer-motion";
import { Menu, X } from "lucide-react";

import { logo } from "../utils";
import { navLists } from "../constants";
import { useState } from "react";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center">
      <nav className="flex w-full screen-max-width items-center justify-between">
        <a href="/">
          <img src={logo} alt="Logo" width={100} height={2} />
        </a>

        {/* Desktop Navigation */}
        <div className="flex flex-1 justify-center max-sm:hidden">
          {navLists.map((nav) => (
            <div
              key={nav.title}
              className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all"
            >
              <a href={nav.link}>{nav.title}</a>
            </div>
          ))}
        </div>

        <button
          className="sm:hidden text-white"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <Menu size={32} />
        </button>
      </nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50 w-screen h-screen overflow-hidden"
          >
            <button
              className="absolute top-5 right-5 text-white"
              onClick={toggleMenu}
              aria-label="Close Menu"
            >
              <X size={32} />
            </button>
            <div className="flex flex-col items-center space-y-6 w-full h-full justify-center">
              {navLists.map((nav) => (
                <motion.a
                  key={nav.title}
                  href={nav.link}
                  className="text-white text-2xl font-semibold"
                  onClick={toggleMenu}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  {nav.title}
                </motion.a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};
