//@ts-nocheck
import {
  admirImgLeft,
  admirImgRight,
  emirMImgLeft,
  emirMImgRight,
  emirTImgLeft,
  emirTImgRight,
  harisImgLeft,
  harisImgRight,
} from "../utils";

import { ProfileProps } from "../models/profile-props.model";

export const teamMembers: ProfileProps[] = [
  {
    quote: "Perfection is not in the details. The details are perfection.",
    leftImage: admirImgLeft,
    rightImage: admirImgRight,
    name: "Admir Tursum",
    position: "CEO",
  },
  {
    quote: "Sky is not the limit. Your mind is.",
    leftImage: emirMImgLeft,
    rightImage: emirMImgLeft,
    name: "Emir Memic",
    position: "Software Developer",
  },
  {
    quote: "You know who I am",
    leftImage: harisImgLeft,
    rightImage: harisImgRight,
    name: "Haris Hanjalic",
    position: "Software Developer",
  },
  {
    quote: "It always seems impossible until it’s done.",
    leftImage: emirTImgLeft,
    rightImage: emirTImgLeft,
    name: "Emir Tukic",
    position: "Graphics Design",
  },
];
