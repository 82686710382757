const PrivacyPolicy = () => {
  return (
    <>
      <section
        id="privacy-policy"
        className="w-screen overflow-hidden h-full common-padding bg-black"
      >
        <div className="screen-max-width">
          <div className="mb-12 w-full md:flex items-end justify-between">
            <h1
              id="title"
              className="text-white lg:text-6xl md:text-5xl text-3xl lg:mb-0 mb-5 mt-5 md:mt-0 font-medium"
            >
              Privacy Policy
            </h1>
          </div>
          <div>
            <p className="text-gray-100 text-justify">
              Last Updated: February 7, 2025
              <br />
              <br />
              At AT Soft, we respect your privacy and are committed to
              protecting your personal information. This Privacy Policy explains
              how we collect, use, and safeguard your information when you visit
              our website and use our services.
              <br />
              <br />
              1. Information We Collect
              <br />
              We collect personal information that you voluntarily provide to us
              when you use our services, contact us, or fill out forms on our
              website. This information may include:
              <br />
              - Name
              <br />
              - Email address
              <br />
              - Phone number
              <br />
              - Business name
              <br />
              - Billing information (if applicable)
              <br />
              <br />
              We may also collect non-personal information such as:
              <br />
              - IP address
              <br />
              - Browser type and version
              <br />
              - Pages visited on our website
              <br />
              - Time spent on the site
              <br />
              - Referring website URLs
              <br />
              <br />
              2. How We Use Your Information
              <br />
              We may use the information we collect to:
              <br />
              - Provide and improve our software development services
              <br />
              - Communicate with you regarding your inquiries or support
              requests
              <br />
              - Process payments for services rendered
              <br />
              - Send you marketing and promotional materials (with your consent)
              <br />
              - Enhance the user experience on our website
              <br />
              <br />
              3. How We Protect Your Information
              <br />
              We take reasonable security measures to protect the personal
              information you provide. However, no method of transmission over
              the internet or electronic storage is completely secure, and we
              cannot guarantee absolute security.
              <br />
              <br />
              4. Sharing Your Information
              <br />
              We do not sell or rent your personal information to third parties.
              However, we may share your information with trusted third parties
              who assist us in operating our website and providing services,
              provided these parties agree to keep your information
              confidential.
              <br />
              <br />
              5. Cookies
              <br />
              Our website may use cookies to enhance the user experience. You
              can choose to disable cookies through your browser settings, but
              this may limit some website functionality.
              <br />
              <br />
              6. Your Rights
              <br />
              You have the right to access, correct, or delete your personal
              information. To exercise these rights or for any privacy-related
              concerns, please contact us at{" "}
              <a
                className="underline text-white"
                href="mailto:info@atsofts.com"
              >
                info@atsofts.com
              </a>
              .
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
