export const BlogCard = ({ post }: any) => {
  function prettifyDate(str: string) {
    const [date] = str.split("T");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  return (
    <div
      key={post.id}
      className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl"
    >
      <a href={`/blog/${post.slug}`}>
        <img
          src={
            import.meta.env.VITE_STRAPI_APP_URL +
              post.cover_image.formats.large.url || "/placeholder.jpg"
          }
          alt={post.title}
          className="w-full h-48 object-cover"
        />
      </a>
      <div className="p-6">
        <h3 className="text-xl font-bold mb-2 text-black">{post.title}</h3>
        <p className="text-gray-600 mb-4">{post.author}</p>
        <span className="text-gray-500 text-sm">
          {prettifyDate(post.createdAt)}
        </span>
      </div>
    </div>
  );
};
