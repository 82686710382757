const TermsOfService = () => {
  return (
    <>
      <section
        id="terms-of-service"
        className="w-screen overflow-hidden h-full common-padding bg-black"
      >
        <div className="screen-max-width">
          <div className="mb-12 w-full md:flex items-end justify-between">
            <h1
              id="title"
              className="text-white lg:text-6xl md:text-5xl text-3xl lg:mb-0 mb-5 mt-5 md:mt-0 font-medium"
            >
              Terms of Service
            </h1>
          </div>
          <div>
            <p className="text-gray-100 text-justify">
              Last Updated: February 7, 2025
              <br />
              <br />
              Welcome to AT Soft! By using our website and services, you agree
              to be bound by the following Terms of Service. Please read these
              terms carefully before using our website or services.
              <br />
              <br />
              1. Acceptance of Terms
              <br />
              By accessing or using the AT Soft website or services, you agree
              to comply with these Terms of Service and all applicable laws and
              regulations. If you do not agree with these terms, you should not
              use our services.
              <br />
              <br />
              2. Services Provided
              <br />
              AT Soft offers software development services, including but not
              limited to web development, software solutions, system
              integrations, and digital marketing services. We reserve the right
              to modify or discontinue any services at any time.
              <br />
              <br />
              3. User Responsibilities
              <br />
              As a user of our website and services, you agree to:
              <br />
              - Provide accurate and complete information when using our
              services.
              <br />
              - Use our services only for lawful purposes.
              <br />
              - Not engage in any activity that disrupts or interferes with the
              functionality of our website or services.
              <br />
              - Respect intellectual property rights, including those of AT Soft
              and third-party content providers.
              <br />
              <br />
              4. Account Registration
              <br />
              To access certain features of our website or services, you may be
              required to register an account. You agree to provide accurate and
              complete information and keep your account details secure. You are
              responsible for all activities that occur under your account.
              <br />
              <br />
              5. Pricing and Payments
              <br />
              All pricing for services provided by AT Soft will be outlined in
              separate agreements or contracts. Payments for services are due
              according to the terms specified in the contract. We reserve the
              right to change our pricing at any time, but any such changes will
              not affect current agreements.
              <br />
              <br />
              6. Intellectual Property
              <br />
              All content, trademarks, logos, and intellectual property found on
              the AT Soft website and in our services are the property of AT
              Soft unless otherwise stated. You may not use, modify, or
              distribute any part of our intellectual property without prior
              written consent.
              <br />
              <br />
              7. Limitation of Liability
              <br />
              AT Soft will not be held liable for any direct, indirect,
              incidental, special, or consequential damages arising from your
              use of our website or services. This includes but is not limited
              to data loss, business interruption, or errors in service
              delivery.
              <br />
              <br />
              8. Termination
              <br />
              AT Soft reserves the right to suspend or terminate your access to
              our website and services at our discretion, without prior notice,
              for any violation of these Terms of Service.
              <br />
              <br />
              9. Privacy
              <br />
              By using our services, you acknowledge and agree to our Privacy
              Policy, which governs the collection, use, and protection of your
              personal information.
              <br />
              <br />
              10. Governing Law
              <br />
              These Terms of Service will be governed by and construed in
              accordance with the laws of Bosnia and Herzegovina, without regard
              to its conflict of law principles.
              <br />
              <br />
              11. Changes to Terms
              <br />
              We may update these Terms of Service from time to time. Any
              changes will be posted on this page with the updated date. It is
              your responsibility to review these terms periodically.
              <br />
              <br />
              12. Contact Us
              <br />
              If you have any questions or concerns regarding these Terms of
              Service, please contact us at{" "}
              <a
                className="text-white underline"
                href="mailto:info@atsofts.com"
              >
                info@atsofts.com
              </a>
              .
              <br />
              <br />{" "}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfService;
