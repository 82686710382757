//@ts-nocheck
import { useEffect, useState } from "react";

import { BlogCard } from "./BlogCard";
import { Link } from "react-router-dom";

// import { fetchBlogPosts } from "../utils/strapi-client";

export const Blog = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // const loadData = async () => {
    //   try {
    //     const response = await fetchBlogPosts();
    //     setPosts(response.data);
    //   } catch (err) {
    //     setError("Failed to fetch blog posts.");
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // loadData();
    setError("Error");
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-black">
        <p className="text-gray-400 text-xl animate-pulse">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-center bg-gradient-to-b from-black h-screen to-black">
        <h2 className="text-2xl md:text-4xl font-semibold text-gray-900 text-white">
          Blog Posts Coming Soon
        </h2>
        <p className="text-gray-500 mt-2 text-lg md:text-xl">
          Stay tuned! New insights and updates will be available shortly.
        </p>
      </div>
    );
  }

  return (
    <section className="bg-black text-white min-h-screen py-20">
      <div className="screen-max-width max-w-6xl mx-auto px-6">
        {/* Page Header */}
        <h2 className="hiw-title">Stay Ahead in Tech</h2>
        <p className="hiw-subtitle">
          Dive into the latest trends, expert insights, and cutting-edge
          innovations in software development.
        </p>

        {/* Blog Post Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10">
          {posts.map((post) => (
            <Link to={`/blog/${post.slug}`} key={post.id} className="group">
              <BlogCard post={post} />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};
