import {
  aluminijImg,
  amicusImg,
  asabankaImg,
  bayerImg,
  burchImg,
  cocacolaImg,
  dmImg,
  eurofarmImg,
  iomunImg,
  kauflandImg,
  lidlImg,
  mahleImg,
  novartisImg,
  novonordiskImg,
  pfizerImg,
  porscheImg,
  raiffeisenImg,
  rocheImg,
  ssstImg,
  telemachImg,
  unicreditImg,
  viatrisImg,
} from "../../src/utils/index";

const logosRow1 = [
  aluminijImg,
  amicusImg,
  asabankaImg,
  bayerImg,
  burchImg,
  cocacolaImg,
  dmImg,
  eurofarmImg,
  iomunImg,
];

const logosRow2 = [
  kauflandImg,
  lidlImg,
  mahleImg,
  novartisImg,
  novonordiskImg,
  pfizerImg,
  porscheImg,
  raiffeisenImg,
  rocheImg,
  ssstImg,
  telemachImg,
  unicreditImg,
  viatrisImg,
];

export const Partners = () => {
  return (
    <div
      className="bg-gradient-to-t from-black via-zinc to-black py-10"
      id="partners"
    >
      <div className="text-center text-white mb-6">
        <h2 className="hiw-title">World-Class Partners</h2>
        <p className="hiw-subtitle">
          Trusted by Mono Apple Authorised Reseller - AT Store Sarajevo.
        </p>
      </div>
      <div className="overflow-hidden whitespace-nowrap relative">
        {/* First row - scrolling left */}
        <div className="flex gap-10 animate-marquee">
          {logosRow1.concat(logosRow1).map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt="Client logo"
              className="w-[145px] h-[74px] sm:w-[290px] sm:h-[148px] object-contain ml-5"
            />
          ))}
        </div>
        {/* Second row - scrolling right */}
        <div className="flex gap-10 animate-marquee-reverse mt-10">
          {logosRow2.concat(logosRow2).map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt="Client logo"
              className="w-[145px] h-[74px] sm:w-[290px] sm:h-[148px] object-contain ml-5"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
