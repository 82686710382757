import { Carousel } from "./Carousel";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export const Solutions = () => {
  useGSAP(() => {
    gsap.to("#title", {
      opacity: 1,
      y: 0,
    });
  }, []);
  return (
    <section
      id="solutions"
      className="w-screen mt-20 overflow-hidden h-full common-padding bg-gradient-to-t from-zinc via-[#E31E44] to-[#E31E44]"
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-end justify-between">
          <h1 id="title" className="section-heading">
            Solutions
          </h1>
        </div>
        <Carousel />
      </div>
    </section>
  );
};
