import axios from "axios";

const API_URL = import.meta.env.VITE_STRAPI_APP_URL;
const BEARER_TOKEN = import.meta.env.VITE_STRAPI_APP_TOKEN;

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${BEARER_TOKEN}`,
  },
});

export const fetchBlogPosts = async () => {
  try {
    const response = await apiClient.get("/api/blogs?populate=*");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBlogPost = async (slug: string) => {
  try {
    const response = await apiClient.get(
      `/api/blogs?filters[slug][$eq]=${slug}&populate=*`
    );
    return response["data"]["data"][0];
  } catch (error) {
    console.error("Error fetching blog post:", error);
    throw error;
  }
};
