import { Route, Routes } from "react-router-dom";

import { Blog } from "./components/Blog";
import { BlogPost } from "./components/BlogPost";
import Careers from "./components/Careers";
import { Footer } from "./components/Footer";
import Landing from "./pages/Landing";
import { Navbar } from "./components/Navbar";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReactGA from "react-ga";
import { RouteModel } from "./models/route-model";
import TermsOfService from "./components/TermsOfService";
import { useEffect } from "react";

const routes: RouteModel[] = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:slug",
    element: <BlogPost />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  ReactGA.initialize(import.meta.env.VITE_GOOGLE_TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
    </>
  );
};

export default App;
