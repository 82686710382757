import { useEffect, useState } from "react";

import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { fetchBlogPost } from "../utils/strapi-client";
import { useParams } from "react-router-dom";

export const BlogPost = () => {
  const [post, setPost] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { slug } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchBlogPost(slug!);
        setPost(response);
      } catch (err) {
        setError("Failed to fetch blog post.");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-black">
        <p className="text-gray-400 text-xl animate-pulse">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-black to-black">
        <h2 className="text-2xl md:text-4xl font-semibold text-gray-900 text-white">
          Blog Posts Coming Soon
        </h2>
        <p className="text-gray-500 mt-2 text-lg md:text-xl">
          Stay tuned! New insights and updates will be available shortly.
        </p>
      </div>
    );
  }

  function prettifyDate(str: string) {
    const [date] = str.split("T");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  return (
    <div className="common-padding bg-black text-white">
      <div className="screen-max-width max-w-4xl mx-auto">
        {/* Post Content */}
        <div className="mt-10 space-y-6">
          {/* Title & Meta Info */}
          <h1 className="text-5xl font-semibold tracking-tight">
            {post.title}
          </h1>
          <div className="text-lg text-gray-700">
            <span>{post.author}</span> •{" "}
            <span>{prettifyDate(post.createdAt)}</span>
          </div>
          {/* Cover Image */}
          <div className="w-full overflow-hidden rounded-3xl shadow-lg">
            <img
              className="w-full h-[450px] object-cover"
              src={`${import.meta.env.VITE_STRAPI_APP_URL}${
                post.cover_image.formats.large.url
              }`}
              alt={post.title}
            />
          </div>

          {/* Content */}
          <div className="text-lg mt-5 leading-relaxed text-white">
            <BlocksRenderer content={post.content} />
          </div>
        </div>
      </div>
    </div>
  );
};
