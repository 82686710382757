import { BlogPosts } from "../components/BlogPosts";
import ContactForm from "../components/ContactForm";
import { Hero } from "../components/Hero";
import Partners from "../components/Partners";
import { Solutions } from "../components/Solutions";
import { Team } from "../components/Team";

const Landing = () => {
  return (
    <main className="bg-black">
      <Hero />
      <Solutions />
      <Team />
      <BlogPosts />
      <Partners />
      <ContactForm />
    </main>
  );
};

export default Landing;
