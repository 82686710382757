//@ts-nocheck
import { pauseImg, playImg, replayImg } from "../utils";
import { useEffect, useRef, useState } from "react";

import gsap from "gsap";
import { hightlightsSlides } from "../constants";
import { useGSAP } from "@gsap/react";

export const Carousel = () => {
  const videoRef = useRef([]);
  const videoSpanRef = useRef([]);
  const videoDivRef = useRef([]);
  const animRef = useRef(null);

  const [video, setVideo] = useState({
    isEnd: false,
    startPlay: true,
    videoId: 0,
    isLastVideo: false,
    isPlaying: true,
  });

  const { isEnd, isLastVideo, startPlay, videoId, isPlaying } = video;

  const pauseCurrentVideo = () => {
    if (videoRef.current[videoId]) {
      videoRef.current[videoId].pause();
      videoRef.current[videoId].currentTime = 0;
    }
  };

  const resetProgressBars = () => {
    hightlightsSlides.forEach((_, index) => {
      gsap.set(videoDivRef.current[index], { width: "12px" });
      gsap.set(videoSpanRef.current[index], {
        width: "0%",
        backgroundColor: "#afafaf",
      });
    });
  };

  const handlePrev = () => {
    pauseCurrentVideo();
    resetProgressBars();
    const prevVideoId =
      (videoId - 1 + hightlightsSlides.length) % hightlightsSlides.length;
    setVideo({ ...video, videoId: prevVideoId, isPlaying: true });
  };

  const handleNext = () => {
    pauseCurrentVideo();
    resetProgressBars();
    const nextVideoId = (videoId + 1) % hightlightsSlides.length;
    setVideo({
      ...video,
      videoId: nextVideoId,
      isEnd: nextVideoId === hightlightsSlides.length - 1,
      isLastVideo: false,
      isPlaying: true,
    });
  };

  const handleDotClick = (i) => {
    pauseCurrentVideo();
    resetProgressBars();
    setVideo({ ...video, videoId: i, isPlaying: true });
  };

  useGSAP(() => {
    gsap.to("#slider", {
      transform: `translateX(${-100 * videoId}%)`,
      duration: 1.5,
      ease: "power2.inOut",
    });
  }, [videoId]);

  useEffect(() => {
    let span = videoSpanRef.current;
    if (animRef.current) {
      animRef.current.kill();
      animRef.current = null;
    }

    resetProgressBars();

    if (span[videoId]) {
      gsap.to(videoDivRef.current[videoId], {
        width: "80px",
        duration: 0.3,
        ease: "power1.out",
      });

      const anim = gsap.to(span[videoId], {
        width: "100%",
        backgroundColor: "white", // 👈 Ensuring active video progress is WHITE
        duration: hightlightsSlides[videoId].videoDuration,
        ease: "none",
        onComplete: () => {
          if (isPlaying) {
            handleNext();
          }
        },
      });

      animRef.current = anim;
    }
  }, [videoId, isPlaying]);

  useEffect(() => {
    if (startPlay) {
      videoRef.current[videoId]?.play();
    } else {
      videoRef.current[videoId]?.pause();
    }
  }, [startPlay, videoId, isPlaying]);

  const handleProcess = (type) => {
    switch (type) {
      case "video-last":
        setVideo({ ...video, isLastVideo: true });
        break;
      case "video-reset":
        setVideo({ ...video, videoId: 0, isLastVideo: false });
        break;
      case "pause":
      case "play":
        setVideo({ ...video, isPlaying: !video.isPlaying });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex items-center">
        {hightlightsSlides.map((list, i) => (
          <div key={list.id} id="slider" className="sm:pr-20 pr-10">
            <div className="video-carousel_container">
              <div className="w-full h-full flex-center rounded-3xl overflow-hidden bg-black">
                <video
                  id="video"
                  playsInline
                  className={`pointer-events-none object-fit opacity-50`}
                  preload="auto"
                  muted
                  ref={(el) => (videoRef.current[i] = el)}
                  onEnded={() =>
                    i !== hightlightsSlides.length - 1
                      ? handleNext()
                      : handleProcess("video-last")
                  }
                >
                  <source src={list.video} type="video/mp4" />
                </video>
                <div className="absolute top-12 left-[5%] z-10">
                  {list.textLists.map((text, i) => (
                    <p
                      key={i}
                      className={`${
                        i === 0
                          ? "font-bold md:text-5xl sm:text-2xl text-lg"
                          : "mt-2 md:mt-3 text-lg md:text-2xl font-thin"
                      }`}
                    >
                      {text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="relative flex-center mt-10">
        {/* <button
          onClick={handlePrev}
          className="mr-4 p-2 rounded-full bg-white/10 hover:bg-white/20 transition"
        >
          ←
        </button> */}

        <div className="flex-center py-5 px-7 bg-gray-300 backdrop-blur rounded-full">
          {hightlightsSlides.map((_, i) => (
            <span
              key={i}
              className="mx-2 w-3 h-3 bg-gray-100 rounded-full relative cursor-pointer"
              ref={(el) => (videoDivRef.current[i] = el)}
              onClick={() => handleDotClick(i)}
            >
              <span
                className={`absolute h-full bg-white w-full rounded-full ${
                  i === videoId ? "bg-gray-200" : "bg-gray-200"
                }`} // 👈 Ensuring only the current video is white
                ref={(el) => (videoSpanRef.current[i] = el)}
              />
            </span>
          ))}
        </div>

        {/* <button
          onClick={handleNext}
          className="ml-4 p-2 rounded-full bg-white/10 hover:bg-white/20 transition"
        >
          →
        </button> */}

        {/* <button className="control-btn ml-4">
          <img
            src={isLastVideo ? replayImg : !isPlaying ? playImg : pauseImg}
            alt={isLastVideo ? "replay" : !isPlaying ? "play" : "pause"}
            onClick={
              isLastVideo
                ? () => handleProcess("video-reset")
                : () => handleProcess(isPlaying ? "pause" : "play")
            }
          />
        </button> */}
      </div>
    </>
  );
};
